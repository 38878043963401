import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

function AdminRoute({ isAdmin, component: Component, ...rest}) {
//   const userSignin = useSelector((state) => state.userSignin);
//   const { loading, isAuthenticated, user ,token} = useSelector((state) => state.user);
  const { loading, isAuthenticated, user,token } = useSelector((state) => state.user);

  return (
    <Fragment>
      {loading === false && (
        <Route
          {...rest}
          render={(props) => {
            if (isAuthenticated === false) {
              return <Redirect to="/login" />;
            }

            if (isAdmin === true && user.role !== "admin") {
              return <Redirect to="/login" />;
            }

            return <Component {...props} />;
          }}
        />
      )}
    </Fragment>
  );
};
export default AdminRoute;
