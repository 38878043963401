import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";

const Privacy = ({ location }) => {
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <title>Hopsies | Privacy Policy</title>
        <meta name="description" content="About page of Hopsies Kids Wear." />
      </Helmet>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>Privacy Policy</BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />

        {/* section title with text */}
        <div className={`welcome-area pt-100 pb-95`}>
          <div className="container">
            <h1 className="text-center">Privacy Policy</h1>
            <div className="welcome-content text-left">
              <h2>Introduction</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Hopsies is committed to protecting your privacy. This Privacy
                Policy outlines how we collect, use, and safeguard your personal
                information when you visit our website and purchase our
                products.
              </p>

              <h2>Information We Collect</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                We collect personal information that you voluntarily provide
                when you place an order, create an account, or subscribe to our
                newsletter. This information may include your name, email
                address, shipping address, and payment details.
              </p>

              <h2>How We Use Your Information</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                We use your personal information to process and fulfill your
                orders, communicate with you, and improve our services. We may
                also use your information to send you promotional offers and
                updates, which you can opt-out of at any time.
              </p>

              <h2>Data Security</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                We implement security measures to protect your personal
                information from unauthorized access, alteration, or disclosure.
                However, no method of transmission over the internet is
                completely secure, and we cannot guarantee absolute security.
              </p>

              <h2>Cookies</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Our website uses cookies to enhance your browsing experience and
                track website usage. Cookies are small data files stored on your
                device that help us understand your preferences. You can adjust
                your browser settings to refuse cookies, but this may affect
                your ability to use certain features of our website.
              </p>

              <h2>Third-Party Services</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                We may share your information with third-party service providers
                who assist us in operating our website and fulfilling orders.
                These third parties are obligated to keep your information
                confidential and are only permitted to use it for specific
                purposes.
              </p>

              <h2>Children's Privacy</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Hopsies does not knowingly collect personal information from
                children under the age of 13. If we become aware that we have
                inadvertently collected such information, we will promptly
                delete it.
              </p>

              <h2>Changes to Privacy Policy</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Hopsies reserves the right to update this Privacy Policy at any
                time. Any changes will be posted on this page, and your
                continued use of our website constitutes acceptance of the
                updated policy.
              </p>

              <h2>Contact Us</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                If you have any questions or concerns about our Terms and
                Conditions or Privacy Policy, please contact us at{" "}
                <a href="mailto:hopsiescare@gmail.com">hopsiescare@gmail.com</a>
                .
              </p>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Privacy.propTypes = {
  location: PropTypes.object,
};

export default Privacy;
