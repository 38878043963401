import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const MobileNavMenu = ({ strings }) => {
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        <li className="menu-item-has-children">
          <Link>SHOP</Link>
          <ul className="sub-menu">
            <li>
              <Link to={"/shop-all"}>SHOP ALL</Link>
            </li>
            <li>
              <Link to={"/shop-grid-frocks"}>FROCKS</Link>
            </li>
            <li>
              <Link to={"/shop-grid-cloth-diapers"}>CLOTH DIAPERS</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/about"}>About us</Link>
        </li>
        <li className="menu-item-has-children">
          <Link to={"/"}>{strings["pages"]}</Link>
          <ul className="sub-menu">
            <li>
              <Link to={"/cart"}>{strings["cart"]}</Link>
            </li>
            <li>
              <Link to={"/checkout"}>{strings["checkout"]}</Link>
            </li>
            <li>
              <Link to={"/wishlist"}>{strings["wishlist"]}</Link>
            </li>
            <li>
              <Link to={"/compare"}>{strings["compare"]}</Link>
            </li>
            <li>
              <Link to={"/my-account"}>{strings["my_account"]}</Link>
            </li>
            <li>
              <Link to={"/login-register"}>{strings["login_register"]}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/contact"}>{strings["contact_us"]}</Link>
        </li>
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(MobileNavMenu);
