import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ isAdmin, component: Component, ...rest }) => {
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);
  console.log(loading,'user')

  return (
    <Fragment>
      {loading === false ? (
        <Route
          {...rest}
          render={(props) => {
            if (isAuthenticated === false) {
              console.log('ok1')
              return <Redirect to="/login-register" />;
            }
            if(isAuthenticated === undefined){
              console.log('ok2')

              return <Redirect to="/login-register" />;
            }

            if (isAdmin === true && user.role !== "admin") {
              console.log('ok3')

              return <Redirect to="/" />;
            }

            return <Component {...props} />;
          }}
        />
      ):(
        <Route
        {...rest}
        render={(props) => {
          if(isAuthenticated === undefined){
            return <Redirect to="/login-register" />;
          }
          return <Component {...props} />;
        }}
      />
      )}
    </Fragment>
  );
};

export default ProtectedRoute;
