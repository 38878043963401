import currencyReducer from "./currencyReducer";
// import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import {  changeUserDetailReducer, userDetailsReducer, userReducer } from "./userReducer";
import { newProductReducer, newReviewReducer, productDetailsReducer, productUpdateReducer } from "./product";
import { allOrdersReducer, myOrdersReducer, newOrderReducer, orderDetailsReducer, orderReducer, orderUpdateReducer } from "./orderReducer";
import { productAllReducer, productReducer } from "./productReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  user: userReducer,
  newProduct:newProductReducer,
  newOrder:newOrderReducer,
  newReview:newReviewReducer,
  allOrders:allOrdersReducer,
  orderDetails:orderDetailsReducer,
  productDetails:productDetailsReducer,
  updateProduct:productUpdateReducer,
  myOrders:myOrdersReducer,
  orderStatus:orderReducer,
  // orderCancel:orderCancelReducer,
 orderUpdate : orderUpdateReducer,
 productAll:productAllReducer,
 changeUserDetails:changeUserDetailReducer,
 userDetails:userDetailsReducer,

});

export default rootReducer;
