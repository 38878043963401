import React from 'react'
import { useEffect } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'

function OrderStatus({order,status,type}) {
  // useEffect(()=>{
    const filterStatus = status.filter((e)=>e.name == type)
    console.log(type,'filterStatus')
  // },[])

  
  return (
    <div>
       <Row className="align-items-center">
                          <Col md="2">
                            <p className="text-muted mb-0 small">Track Order</p>
                          </Col>
                          <Col md="10">
                            <ProgressBar style={{ height: "6px", borderRadius: "16px" }}>
                              <ProgressBar
                                style={{ borderRadius: "16px", backgroundColor: "#a8729a" }}
                                now={filterStatus[0]?.value}
                              />
                            </ProgressBar>
                            <div className="d-flex justify-content-around mb-1">
                              {status?.map((stat)=>(
                                <p className="text-muted mt-1 mb-0 small ">
                                {stat.name}
                              </p>
                              ))}
                          
                            </div>
                          </Col>
                          </Row>
                          </div> 
  )
}

export default OrderStatus