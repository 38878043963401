import axios from "axios";
import { LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_FAIL, LOGOUT_SUCCESS, REGISTER_USER_FAIL, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, UPDATE_PASSWORD_FAIL, UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, } from "../constants/userConstan";
import { BaseUrl } from "../constants/api";
import { HTTP } from "../api";

export const login = (credential) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json" } };
  
      const { data } = await axios.post(
        `${BaseUrl}/api/v1/login`,
        credential,
        config
      );
  
      dispatch({ type: LOGIN_SUCCESS, payload: data.user,token:data.token });
      localStorage.setItem("userInfo",data.token)
    } catch (error) {
      dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
    }
  };


  export const logout = () => async (dispatch) => {
    try {
      await axios.get(`${BaseUrl}/api/v1/logout`);
      localStorage.removeItem("userInfo")
  
      dispatch({ type: LOGOUT_SUCCESS });

    } catch (error) {
      dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
    }
  };


  export const changePassword =(passwordData)=>async(dispatch)=>{
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    try {
      const { data } = await HTTP.put(`/api/v1/password/update`, passwordData);
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({
        type: UPDATE_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
    }
  }


  export const registerAction = (userData) => async (dispatch) => {
    dispatch({ type: REGISTER_USER_REQUEST });
    try {
      const { data } = await axios.post(`${BaseUrl}/api/v1/register`, userData);
      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  

  export const getLoggedInUserDetails =()=>async(dispatch)=>{
      dispatch({type:LOAD_USER_REQUEST})
      try{
        const {data} = await HTTP.get('/api/v1/me')
        dispatch({type:LOAD_USER_SUCCESS,payload:data.user})
      }catch (error) {
        dispatch({
          type: LOAD_USER_FAIL,
          payload: error.response.data.message,
        });
      }
  }


  export const updateUserProfile =(details,addToast)=>async(dispatch)=>{
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      const { data } = await HTTP.put(`/api/v1/me/update`, details);
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data.user });
     
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: error.response.data.message,
      });
    }
  }