import { FETCH_PRODUCTS_SUCCESS } from "../actions/productActions";
import { GET_ALL_PRODUCT_FAIL, GET_ALL_PRODUCT_REQUEST, GET_ALL_PRODUCT_SUCCESS } from "../constants/productConstant";

const initState = {
  products: []
};

export const productReducer = (state = initState, action) => {
  if (action.type === FETCH_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products: action.payload
    };
  }

  return state;
};

export const productAllReducer =(state={products:[]},action)=>{
  switch(action.type){
    case GET_ALL_PRODUCT_REQUEST:
      return {loading:true}
    case GET_ALL_PRODUCT_SUCCESS:
      return {loading:false,products:action.payload.product} 
    case GET_ALL_PRODUCT_FAIL:
      return {loaidng:false,error:action.payload}   
      
      default:
        return state;
  }
}






