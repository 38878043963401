import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const referenceNum = searchParams.get('reference');

    return (
        <div>
            <div>
                <h1 style={{ textTransform: 'uppercase' }}>Order Successful</h1>
                <p>Reference No. 1233</p>
            </div>
        </div>
    );
};

export default PaymentSuccess;
