import { CLEAR_ERRORS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_FAIL, LOGOUT_SUCCESS, REGISTER_USER_FAIL, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, UPDATE_PASSWORD_FAIL, UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_RESET, UPDATE_PASSWORD_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_REQUEST, UPDATE_USER_RESET, UPDATE_USER_SUCCESS } from "../constants/userConstan";

export const userReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case LOGIN_REQUEST:
      case REGISTER_USER_REQUEST:
      case LOAD_USER_REQUEST:
        return {
          loading: true,
          isAuthenticated: false,
        };
      case LOGIN_SUCCESS:
      case REGISTER_USER_SUCCESS:
      case LOAD_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
          user: action.payload,
          token:action.token
        };
  
      case LOGOUT_SUCCESS:
        return {
          loading: false,
          user: null,
          isAuthenticated: false,
          token:null,
        };
      case LOGIN_FAIL:
      case REGISTER_USER_FAIL:
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          user: null,
          error: action.payload,
        };
  
      case LOAD_USER_FAIL:
        return {
          loading: false,
          isAuthenticated: false,
          user: null,
          error: action.payload,
        };
  
      case LOGOUT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };


  export const changeUserDetailReducer =(state={},action)=>{
    switch(action.type){
      case UPDATE_PASSWORD_REQUEST:
      case UPDATE_USER_REQUEST:
        return {loading:true}
      case UPDATE_PASSWORD_SUCCESS:
        return {loading:false,successPassword:true} 
      case UPDATE_USER_SUCCESS:
        return {loading:false,successDetails:true} 
      case UPDATE_PASSWORD_FAIL:
      case UPDATE_USER_FAIL:
        return {loaidng:false,error:action.payload}  
      case UPDATE_PASSWORD_RESET:
      case UPDATE_USER_RESET:
      return {}  
        default:
          return state;
    }
  }

  export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case LOAD_USER_REQUEST:
        return {
          loading: true,
        };
      case LOAD_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
  
      case LOAD_USER_FAIL:
        return {
          user: null,
          error: action.payload,
        };

      default:
        return state;
    }
  };