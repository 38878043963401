import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

const SizeGuide = ({ location }) => {
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <title>Hopsies | Kids Dress Size Guide</title>
        <meta
          name="description"
          content="Size guide for kids' frocks and cloth diapers at Hopsies."
        />
      </Helmet>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Size Guide
      </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="size-guide-area pb-80 pt-100">
          <div className="container">
            <div className="row">
              <div className="ml-auto mr-auto col-lg-9">
                <div className="size-guide-wrapper">
                  <Accordion defaultActiveKey="0">
                    <Card className="single-size-guide mb-20">
                      <Card.Header className="panel-heading">
                        <Accordion.Toggle variant="link" eventKey="0">
                          <h3 className="panel-title">
                            <span>1 .</span> Frocks Size Guide
                          </h3>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="size-guide-info-wrapper">
                            <h4>Kids Frocks Sizes</h4>
                            <ul>
                              <li>Size 90: 1-2 years</li>
                              <li>Size 100: 2-3 years</li>
                              <li>Size 110: 3-4 years</li>
                              <li>Size 120: 4-5 years</li>
                            </ul>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="single-size-guide mb-20">
                      <Card.Header className="panel-heading">
                        <Accordion.Toggle variant="link" eventKey="1">
                          <h3 className="panel-title">
                            <span>2 .</span> Cloth Diapers Size Guide
                          </h3>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <div className="size-guide-info-wrapper">
                            <h4>Cloth Diapers Sizes</h4>
                            <ul>
                              <li>Size S: Up to 6 months</li>
                              <li>Size M: 6-12 months</li>
                              <li>Size L: 12-24 months</li>
                            </ul>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

SizeGuide.propTypes = {
  location: PropTypes.object,
};

export default SizeGuide;
