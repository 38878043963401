import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

const ErrorPage = () => {

  return (
    <Fragment>
      <Helmet>
        <title>Hopsies | Not Found</title>
        <meta name="description" content="404 page of Hopsies" />
      </Helmet>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      {/* <BreadcrumbsItem to={pathname}>4 page</BreadcrumbsItem> */}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="error-area pt-40 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 text-center">
                <div className="error">
                  <h1>404</h1>
                  <h2> Something went wrong</h2>
                  {/* <p>
                    Sorry but the page you are looking for does not exist, have
                    been removed, name changed or is temporarity unavailable.
                  </p> */}
                  {/* <form className="searchform mb-50">
                    <input
                      type="text"
                      name="search"
                      id="error_search"
                      placeholder="Search..."
                      className="searchform__input"
                    />
                    <button type="submit" className="searchform__submit">
                      <i className="fa fa-search" />
                    </button>
                  </form> */}
                  <Link to={"/"} className="error-btn">
                    Back to home page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

ErrorPage.propTypes = {
  location: PropTypes.object,
};

export default ErrorPage;
