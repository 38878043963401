import React from "react";

import "../assets/css/admin.css";
import "../assets/css/fontawesome.css";

import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../wrappers/admin/Sidebar";
import Navbar from "../wrappers/admin/Navbar";
import Footer from "../wrappers/admin/Footer";

const AdminLayout = ({ children }) => {
  const [isSidebarToggled, setSidebarToggled] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarToggled(!isSidebarToggled);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        // Hide the sidebar on small screens
        setSidebarToggled(false);
      }
      if (window.innerWidth < 480 && !isSidebarToggled) {
        // Toggle the sidebar on screens below 480px
        setSidebarToggled(true);
      }
    }

    function handleScroll() {
      if (window.scrollY > 100) {
        // Handle scroll-to-top button visibility
        // Implement your own scroll-to-top button logic here
      } else {
        // Implement your own scroll-to-top button logic here
      }
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up event listeners on unmount
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSidebarToggled]);

  useEffect(() => {
    // Prevent the content wrapper from scrolling when the fixed side navigation is hovered over
    const sidebar = document.querySelector("body.fixed-nav .sidebar");
    if (sidebar) {
      sidebar.addEventListener("mousewheel", handleMouseWheel);
      sidebar.addEventListener("DOMMouseScroll", handleMouseWheel);
      sidebar.addEventListener("wheel", handleMouseWheel);
    }

    function handleMouseWheel(e) {
      if (window.innerWidth > 768) {
        const delta = e.wheelDelta || -e.detail;
        sidebar.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      }
    }

    return () => {
      // Clean up event listeners on unmount
      if (sidebar) {
        sidebar.removeEventListener("mousewheel", handleMouseWheel);
        sidebar.removeEventListener("DOMMouseScroll", handleMouseWheel);
        sidebar.removeEventListener("wheel", handleMouseWheel);
      }
    };
  }, []);

  return (
    <div
      id="page-top"
      className={`${isSidebarToggled ? "sidebar-toggled" : ""}`}
    >
      <div id="wrapper">
        <Sidebar
          isSidebarToggled={isSidebarToggled}
          handleSidebarToggle={handleSidebarToggle}
        />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar handleSidebarToggle={handleSidebarToggle} />
            {children}
          </div>

          <Footer />
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
};

export default AdminLayout;
