import React, { useEffect, useState } from 'react'
import AdminLayout from '../../layouts/AdminLayout'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetails, updateOrder, updateReturnStatus } from '../../redux/actions/orderAction';
import { Button, Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import MessageBox from '../../components/common/MessageBox';
import { isEligibleForReturn } from '../other/OrderDetails';
import OrderCard from '../../components/common/OrderCard';
import Loader from '../../components/common/Loader';
import OrderStatus from '../../components/common/OrderStatus';
import LoadingBox from '../../components/common/LoadingBox';
import { UPDATE_ORDER_RESET } from '../../redux/constants/orderConstant';
import { useToasts } from 'react-toast-notifications';

function AdminOrderDetail() {
    const { loading, error, order } = useSelector((state) => state.orderDetails);
    const { loading:loadingUpdate, error:errorUpdate, success,isUpdated ,statusReturn} = useSelector((state) => state.orderStatus);

    const { id } = useParams();
    const dispatch = useDispatch()
    const [status, setStatus] = useState('');
    const { addToast } = useToasts();

    const handleStatusChange = (newStatus) => {
      setStatus(newStatus);
    };

    useEffect(()=>{
        dispatch(getOrderDetails(id))
      },[success,loadingUpdate])


      const submitHandler=(id)=>{
      
        dispatch(updateOrder(id,status))
       }


       useEffect(()=>{
        if (success) {
          addToast("order updated ", { appearance: "success", autoDismiss: true });
          dispatch({type:UPDATE_ORDER_RESET})
        //   history.push('/admin/products')
        }
        // if(successUpdate){
        //   addToast("product Updated ", { appearance: "success", autoDismiss: true });
        //   dispatch({type:UPDATE_PRODUCT_RESET})
        //   history.push('/admin/products')
        // }
      },[success])


      const orderStatus=[
        {
          name:'Order placed',
          value:20
        },
        {
          name:'Ready to shipped',
          value:40
        },
        {
          name:'Shipped',
          value:60
        },
        {
          name:'Out for delivery',
          value:80
        },
        {
          name:'Deliverd',
          value:100
        },
      ]

      const returnStatus=[
        {
          name: 'Pending',
          value: 24,
        },
        {
          name: 'Refund Initiated',
          value: 50,
        },
        {
          name: 'Refund Accepted',
          value: 75,
        },
        {
          name: 'Refunded',
          value: 100,
        },
      ]

      const returnHandler=(id)=>{
        dispatch(updateReturnStatus(id,status))
      }
    

  return (
    <div>
           <AdminLayout>
            <Container>
           {(loading || loadingUpdate) ? (<Loader/>):error ? <MessageBox>{error}</MessageBox>:
                  order ? (
                    <Card className="shadow-0 border mb-4">
                      <Card.Body>
                        <OrderCard
                        order={order}
                        isEligibleForReturn={isEligibleForReturn}
                
                        />
                   

                        {order?.orderStatus !== 'Cancelled' &&  order?.returnDetails?.returnStatus === null ? (
                            <>
                          <OrderStatus
                          order={order}
                          status={orderStatus}
                          type={order?.orderStatus}/>
                        
                        <Row className='update-order'>
                            <Col>
                            <h2>Change order status</h2> 
                            </Col>
                            <Col>
                            <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                 Current Status: {status ? status : order?.orderStatus}
                               </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {orderStatus.map((option,index)=>(
                                <Dropdown.Item key={index}  active={order?.orderStatus === option.name}  onClick={() => handleStatusChange(option.name)}>{option.name}</Dropdown.Item>
                                ))}
                        
                            </Dropdown.Menu>
                        </Dropdown>
                            </Col>
                            <Col>
                              <Button onClick={()=>submitHandler(order?._id)}>{loadingUpdate ? <LoadingBox/>: 'Save'}</Button>
                            </Col>
                            
                        </Row>
                        </>
                       ):(
                        <>
                        <OrderStatus
                        order={order.returnDetails}
                        status={returnStatus}
                        type={order?.returnDetails?.returnStatus}
                        />

                        <Row className='update-order'>
                        <Col>
                        <h2>Change Return status</h2> 
                        </Col>
                        <Col>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                             Current Status: {status ? status : order.orderStatus }
                           </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {returnStatus.map((option,index)=>(
                            <Dropdown.Item key={index}  active={order?.returnDetails?.returnStatus === option.name}  onClick={() => handleStatusChange(option.name)}>{option.name}</Dropdown.Item>
                            ))}
                    
                        </Dropdown.Menu>
                    </Dropdown>
                        </Col>
                        <Col>
                          <Button onClick={()=>returnHandler(order?._id)}>{loadingUpdate ? <LoadingBox/>: 'Save'}</Button>
                        </Col>
                        
                    </Row>
                    </>
                       )}

                    {/* {order.returnDetails.returnStatus && (
                        <OrderStatus
                        order={order.returnDetails}
                        status={returnStatus}/>
                    )} */}
                     
                    </Card.Body>
                  </Card>
                  ):(
                    <MessageBox>No order Yet</MessageBox>
                  )}
                </Container>
           </AdminLayout>
    </div>
  )
}

export default AdminOrderDetail