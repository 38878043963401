import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import {
    Card,
    Container,
    Row,
    Col,
    ProgressBar,
    Modal,
    Form,
    Button,
    Dropdown
  } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrderApi, getOrderDetails, myOrdersGet, returnOrderApi } from "../../redux/actions/orderAction";
import Loader from "../../components/common/Loader";
import MessageBox from "../../components/common/MessageBox";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useStateManager } from "react-select";
import OrderCard from "../../components/common/OrderCard";
// import OrderStatus from "../../components/common/OrderStatus";
import OrderStatus  from "../../components/common/OrderStatus";
import { RETURN_ORDER_RESET } from "../../redux/constants/orderConstant";
import { useToasts } from "react-toast-notifications";

  
export const  isEligibleForReturn=(order)=>{
  const deliveredDate = new Date(order?.deliveredAt);
  const returnDeadline = new Date(deliveredDate.getTime() + 3 * 24 * 60 * 60 * 1000); // Add 3 days in milliseconds

  const currentDate = new Date();

  return currentDate <= returnDeadline;

}

const OrderDetails = ({ location }) => {
    const { loading, error, order } = useSelector((state) => state.orderDetails);
    const { loading:LoadingReturn, error:errorReturn, sucessReturn } = useSelector((state) => state.orderUpdate);

    const dispatch = useDispatch()
    const { id } = useParams();
    const [reason, setReason] = useState('')
  const { pathname } = location;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { addToast } = useToasts();

  useEffect(()=>{
    dispatch(getOrderDetails(id))
  },[sucessReturn])

  useEffect(() => {
    if(sucessReturn){

    addToast("return requested ", { appearance: "success", autoDismiss: true });
    dispatch({type:RETURN_ORDER_RESET})
    setShow(false)
    // history.push('/admin/products')
  }
  }, [sucessReturn])
  


const cancelButton = () => {
  dispatch(cancelOrderApi(order?._id))
    .then(() => {
      window.location.reload();  // Reload the page after successful cancellation
    })
    .catch((error) => {
      console.error("Order cancellation failed:", error);  // Handle any errors
    });
};

  
  

  const returnApi=()=>{
    // dispatch(returnOrderApi())
  }

  const handleReturn=()=>{

    dispatch(returnOrderApi(order?._id,reason))
  }

  const orderStatus=[
    {
          name:'Order placed',
          value:20
        },
        {
          name:'Ready to shipped',
          value:40
        },
        {
          name:'Shipped',
          value:60
        },
        {
          name:'Out for delivery',
          value:80
        },
        {
          name:'Deliverd',
          value:100
        },
  ]

  const returnStatus=[

        {
          name: 'Pending',
          value: 24,
        },
        {
          name: 'Refund Initiated',
          value: 50,
        },
        {
          name: 'Refund Accepted',
          value: 75,
        },
        {
          name: 'Refunded',
          value: 100,
        },
  ]

  return (
    <Fragment>
      <Helmet>
        <title>Hopsies | My Orders</title>
        <meta name="description" content="404 page of Hopsies" />
      </Helmet>
      <BreadcrumbsItem to={"/my-orders"}>Orders</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>
      Order Details
      </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <section className="h-100 gradient-custom" style={{ backgroundColor: "#eee" }}>
        <Container className="py-5 h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg="12" xl="12">
              <Card style={{ borderRadius: "10px" }}>
                <Card.Header className="px-4 py-5">
                  {order && (
                    <>
                  <h5 className="text-muted mb-0">
                    Thanks for your Order,{" "}
                    <span style={{ color: "#a8729a" }}>{order?.user?.name}</span>!
                  </h5>
                  <p>
                    <span>Order Number : #{order?.orderNumber}</span>
                  </p>
                  </>
                  )}
                </Card.Header>
                <Card.Body className="p-4">
                 

    
                  {loading ? (<Loader/>):error ? <MessageBox>{error}</MessageBox>:
                  order ? (
                    <Card className="shadow-0 border mb-4">
                      <Card.Body>
                        <OrderCard
                          order={order}
                          isEligibleForReturn={isEligibleForReturn}
                          cancelButton={cancelButton}
                          handleShow={handleShow} />

{order?.orderStatus !== 'Cancelled' &&  order?.returnDetails?.returnStatus === null ? (
                            <>
                          <OrderStatus
                          order={order}
                          status={orderStatus}
                          type={order?.orderStatus}/>
                    
                        </>
                       ):(
                        <OrderStatus
                        order={order.returnDetails}
                        status={returnStatus}
                        type={order?.returnDetails?.returnStatus}/>
                       )}
                     
                     
                    </Card.Body>
                  </Card>
                  ):(
                    <MessageBox>No order Yet</MessageBox>
                  )}
                 
                </Card.Body>
                <Card.Footer className="border-0 px-4 py-5" style={{ backgroundColor: "#a8729a", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                  <h5 className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0">
                    Total paid: <span className="h2 mb-0 ms-2">₹{order?.totalPrice}</span>
                  </h5>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              onChange={(e)=>setReason(e.target.value)}
            >
              <Form.Label>Example textarea</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleReturn}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      </section>
      </LayoutOne>
    </Fragment>
  );
};

OrderDetails.propTypes = {
  location: PropTypes.object,
};

export default OrderDetails;
