import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link to={"/shop-all"}>{strings["shop-all"]}</Link>
          </li>
          <li>
            <Link to={"/shop-grid-frocks"}>{strings["frocks"]}</Link>
          </li>
          <li>
            <Link to={"/shop-grid-cloth-diapers"}>
              {strings["cloth-diapers"]}
            </Link>
          </li>
          {/* <li>
            <Link to={"/girl"}>
              {strings["girls"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={"/girl/tops&t-shirts"}>
                  {strings["tops&t-shirts"]}
                </Link>
              </li>
              <li>
                <Link to={"/dresses&frocks"}>{strings["dresses&frocks"]}</Link>
              </li>
              <li>
                <Link to={"/jeans&trousers"}>{strings["jeans&trousers"]}</Link>
              </li>
              <li>
                <Link to={"/leggings"}>{strings["leggings"]}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"/girl"}>
              {strings["boys"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={"/boys/boys_t-shirts"}>
                  {strings["boys_t-shirts"]}
                </Link>
              </li>
              <li>
                <Link to={"/boys/shirts"}>{strings["boys_shirts"]}</Link>
              </li>
              <li>
                <Link to={"/boys/jeans&trousers"}>
                  {strings["boys_jeans&trousers"]}
                </Link>
              </li>
              <li>
                <Link to={"/boys/shorts"}>{strings["boys_shorts"]}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"/accessories"}>
              {strings["accessories"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={"/accessories/footwear"}>{strings["footwear"]}</Link>
              </li>
              <li>
                <Link to={"/accessories/bags"}>{strings["bags"]}</Link>
              </li>
              <li>
                <Link to={"/accessories/hair_accessories"}>
                  {strings["hair_accessories"]}
                </Link>
              </li>
              <li>
                <Link to={"/accessories/caps&gloves"}>
                  {strings["caps&gloves"]}
                </Link>
              </li>
              <li>
                <Link to={"/accessories/scarfs"}>{strings["scarfs"]}</Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default multilanguage(NavMenu);
