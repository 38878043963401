import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      <div className="footer-logo">
        <Link to={"/"}>
          <img alt="" src={footerLogo} />
        </Link>
      </div>
      <p style={{ textAlign: "center" }}>
        &copy; {new Date().getFullYear()} <Link to={"/about"}>Hopsies.</Link>{" "}
        <span style={{ display: "inline-table" }}>All Rights Reserved</span>
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
