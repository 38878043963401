import React from 'react'
import MessageBox from './MessageBox'
import { Card, Col, Row } from 'react-bootstrap'

function OrderCard({order,isEligibleForReturn,cancelButton,handleShow}) {
  return (
    <div>
             {order?.orderItems?.map((item)=>(
                            <>                                  
                              <Row>
                                    <Col md="2">
                                    <Card.Img
                                        src={item.image}
                                        fluid
                                        alt="Phone"
                                    />
                                    </Col>
                                    <Col md="3" className="text-center d-flex justify-content-center align-items-center">
                                    <p className="text-muted mb-0">{item.name}</p>
                                    </Col>
                                   
                                    <Col md="4" className="text-center d-flex justify-content-center  d-flex flex-column">
                                      <p className="text-muted mb-0 small">Quantity: {item.quantity}</p>
                                      <p className="text-muted mb-0 small">Price: ₹ {item.price}</p>
                                    </Col>
                                    <Col md="2" className="text-center d-flex justify-content-center align-items-center pro-details-cart btn-hover cancel">
                                    
                                   {cancelButton &&  ( order?.orderStatus === 'Order placed' || order?.orderStatus === 'Ready to shipped')  && order?.orderStatus !== 'Cancelled' && (<button onClick={cancelButton}>Cancel</button>)}
                                   {(order?.orderStatus === 'Shipped' || order?.orderStatus === 'Out for delivery' || order?.orderStatus === 'Deliverd') && (<MessageBox variant='danger'>Order can not be cancelled now</MessageBox>)}  
                                   {order?.returnDetails.returnStatus === null &&  cancelButton && order?.orderStatus === 'Deliverd'  && isEligibleForReturn(order) &&
                                    (
                                    <button onClick={handleShow}>Return</button>
                                    )
                                      }  
                                  {order?.orderStatus === 'Cancelled' && (<MessageBox variant='danger'>Order Cancelled</MessageBox>)}
                                  
                                    </Col>
                                    </Row>
                                    <hr className="mb-4" style={{ backgroundColor: "#e0e0e0", opacity: 1 }} />
                                    </>

                        ))}
    </div>
  )
  
}

export default OrderCard