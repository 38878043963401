import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [accordion, setAccordion] = useState({
    about: false,
    links: false,
    quickLinks: false,
  });

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const toggleAccordion = (section) => {
    setAccordion((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <footer
      className={`footer-area ${
        backgroundColorClass ? backgroundColorClass : ""
      } ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${extraFooterClass ? extraFooterClass : ""} ${
        spaceLeftClass ? spaceLeftClass : ""
      } ${spaceRightClass ? spaceRightClass : ""}`}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${
              sideMenu ? "col-xl-2 col-lg-12" : "col-xl-2 col-lg-12"
            }`}
          >
            <FooterCopyright
              footerLogo="/assets/img/logo/logo-2.png"
              spaceBottomClass="mb-30"
            />
          </div>

          {/* ABOUT US Section */}
          <div
            className={`${
              sideMenu ? "col-xl-2 col-lg-4" : "col-xl-2 col-lg-4"
            }`}
          >
            <div
              className="footer-widget mb-10 ml-30"
              onClick={() => toggleAccordion("about")}
            >
              <div className="footer-title head">
                <h3>WHO WE ARE</h3>
              </div>
              <div className={`footer-list ${accordion.about ? "open" : ""}`}>
                <ul>
                  <li>
                    <Link to={"/about"}>About us</Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>Contact</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hopsieskids/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <Link to={"/faq"}>FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* USEFUL LINKS Section */}
          <div
            className={`${
              sideMenu ? "col-xl-2 col-lg-4" : "col-xl-2 col-lg-4"
            }`}
          >
            <div
              className="footer-widget mb-10 ml-30"
              onClick={() => toggleAccordion("links")}
            >
              <div className="footer-title head">
                <h3>USEFUL LINKS</h3>
              </div>
              <div className={`footer-list ${accordion.links ? "open" : ""}`}>
                <ul>
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/terms-and-conditions"}>T&C</Link>
                  </li>
                  <li>
                    <Link to={"/cancellation-policy"}>Cancellation</Link>
                  </li>
                  <li>
                    <Link to={"/size-guide"}>Size guide</Link>
                  </li>
                  <li>
                    <a
                      href={"https://www.dtdc.in/tracking.asp"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Orders tracking
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* QUICK LINKS Section */}
          <div
            className={`${
              sideMenu ? "col-xl-3 col-lg-4" : "col-xl-2 col-lg-4"
            }`}
          >
            <div
              className="footer-widget mb-20 ml-30"
              onClick={() => toggleAccordion("quickLinks")}
            >
              <div className="footer-title head">
                <h3>QUICK LINKS</h3>
              </div>
              <div
                className={`footer-list ${accordion.quickLinks ? "open" : ""}`}
              >
                <ul>
                  <li>
                    <Link to={"/shop-all"}>SHOP ALL</Link>
                  </li>
                  <li>
                    <Link to={"/shop-grid-frocks"}>FROCKS</Link>
                  </li>
                  <li>
                    <Link to={"/shop-grid-cloth-diapers"}>CLOTH DIAPERS</Link>
                  </li>
                  <li>
                    <Link to={"/login-register"}>LOGIN & REGISTER</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`${
              sideMenu ? "col-xl-3 col-lg-12" : "col-xl-4 col-lg-12"
            }`}
          >
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            />
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
