import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";

const Terms = ({ location }) => {
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <title>Hopsies | Terms & Conditions</title>
        <meta name="description" content="About page of Hopsies Kids Wear." />
      </Helmet>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>Terms & Conditions</BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />

        {/* section title with text */}
        <div className={`welcome-area pt-100 pb-95`}>
          <div className="container">
            <h1 className="text-center">Terms & Conditions</h1>
            <div className="welcome-content text-left pb-4">
              <h2>Introduction</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Welcome to Hopsies! By using our website and purchasing our
                products, you agree to the following terms and conditions.
              </p>

              <h2>Products and Pricing</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                All prices listed on our website are in Indian Rupees (INR) and
                are subject to change without notice. We strive to ensure that
                all information regarding product descriptions, pricing, and
                availability is accurate, but errors may occasionally occur. In
                the event of an error, we reserve the right to correct it and to
                refuse or cancel any orders placed.
              </p>

              <h2>Ordering and Payment</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                When you place an order with Hopsies, you agree that all
                information provided is accurate and complete. We accept various
                payment methods, excluding PayPal. Your order will be processed
                once payment has been confirmed.
              </p>

              <h2>Shipping and Delivery</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                We offer free shipping on orders over ₹500 within India. Orders
                are typically processed and shipped within 1-3 business days.
                Delivery times may vary depending on your location, generally
                taking 5 to 7 days. Hopsies is not responsible for delays caused
                by carriers or customs.
              </p>

              <h2>Returns and Exchanges</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                We do not offer returns or exchanges unless the item is
                defective or damaged upon arrival. If you receive a defective
                item, please contact us within 7 days of receiving your order to
                arrange for a replacement.
              </p>

              <h2>Intellectual Property</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                All content on the Hopsies website, including text, images, and
                logos, is the property of Hopsies and is protected by copyright
                and trademark laws. Unauthorized use of our content is
                prohibited.
              </p>

              <h2>Limitation of Liability</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Hopsies is not liable for any direct, indirect, incidental, or
                consequential damages arising from the use of our website or
                products. Our maximum liability to you shall not exceed the
                amount paid for the products in question.
              </p>

              <h2>Governing Law</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                These terms and conditions are governed by the laws of India.
                Any disputes arising from the use of our website or products
                shall be resolved in the courts of India.
              </p>

              <h2>Changes to Terms and Conditions</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Hopsies reserves the right to update or modify these terms and
                conditions at any time without prior notice. Your continued use
                of our website constitutes acceptance of any changes.
              </p>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Terms.propTypes = {
  location: PropTypes.object,
};

export default Terms;
