import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState, Component } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect, useSelector } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import axios from "axios";
import PaymentSuccess from "./wrappers/product/PaymentSuccess";
import AdminOrderDetail from "./pages/admin/AdminOrderDetail";
import AdminRoute from "./components/common/AdminRoute";
import ProtectedRoute from "./components/common/ProtectedRoute";
import ErrorPage from "./pages/other/ErrorPage";
import Terms from "./pages/other/Terms";
import Privacy from "./pages/other/Privacy";
import FAQ from "./pages/other/FAQ";
import SizeGuide from "./pages/other/SizeGuide";
import Cancellation from "./pages/other/Cancellation";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridFrocks = lazy(() => import("./pages/shop/ShopGridFrocks"));
const ShopGridDiapers = lazy(() => import("./pages/shop/ShopGridDiapers"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

// Admin Pages
const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
const Products = lazy(() => import("./pages/admin/Products"));
const Orders = lazy(() => import("./pages/admin/Orders"));
const MyOrder = lazy(() => import("./pages/other/MyOrders"));
const OrderDetails = lazy(() => import("./pages/other/OrderDetails"));

const CreateProduct = lazy(() => import("./pages/admin/CreateProduct"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service if needed
    console.error("Error caught by error boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

const App = (props) => {
  const { loading, user, token } = useSelector((state) => state.user);
  // const [stripeApiKey, setStripeApiKey] = useState("");

  // async function getStripeApiKey() {
  //   const { data } = await axios.get("/api/v1/stripeapikey");

  //   setStripeApiKey(data.stripeApiKey);
  // }
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
    // getStripeApiKey()
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                      <span>hey</span>
                      <span></span>
                    </div>
                  </div>
                }
              >
                <Switch>
                  <Route exact path="/" component={HomeFashion} />

                  {/* Shop product pages */}
                  <Route
                    path="/product/:id"
                    render={(routeProps) => (
                      <Product
                        {...routeProps}
                        key={routeProps.match.params._id}
                      />
                    )}
                  />
                  <Route path="/shop-all" component={ShopGridFullWidth} />
                  <Route path="/shop-grid-frocks" component={ShopGridFrocks} />
                  <Route
                    path="/shop-grid-cloth-diapers"
                    component={ShopGridDiapers}
                  />
                  <ProtectedRoute
                    path="/paymentsuccess"
                    component={PaymentSuccess}
                  />

                  <Route
                    path={"/shop-grid-no-sidebar"}
                    component={ShopGridNoSidebar}
                  />

                  {/* Other pages */}
                  <Route path={"/about"} component={About} />
                  <Route path={"/faq"} component={FAQ} />
                  <Route path={"/size-guide"} component={SizeGuide} />
                  <Route path={"/terms-and-conditions"} component={Terms} />
                  <Route path={"/privacy-policy"} component={Privacy} />
                  <Route path={"/cancellation-policy"} component={Cancellation} />

                  <Route path={"/contact"} component={Contact} />
                  <Route path={"/login-register"} component={LoginRegister} />

                  <Route path={"/cart"} component={Cart} />
                  <Route path={"/wishlist"} component={Wishlist} />
                  <Route path={"/compare"} component={Compare} />
                  <ProtectedRoute path={"/checkout"} component={Checkout} />
                  <ProtectedRoute path={"/my-account"} component={MyAccount} />

                  {/* Admin Pages */}
                  <ProtectedRoute
                    isAdmin={true}
                    exact
                    path={"/admin"}
                    component={Dashboard}
                  />
                  <ProtectedRoute
                    isAdmin={true}
                    exact
                    path={"/admin/products"}
                    component={Products}
                  />
                  <ProtectedRoute
                    isAdmin={true}
                    exact
                    path={"/admin/orders"}
                    component={Orders}
                  />
                  <ProtectedRoute
                    isAdmin={true}
                    exact
                    path={"/admin/products/create/:id?"}
                    component={CreateProduct}
                  />
                  <ProtectedRoute
                    path={"/my-orders"}
                    exact
                    component={MyOrder}
                  />
                  <ProtectedRoute
                    exact
                    path={"/my-orders/:id"}
                    component={OrderDetails}
                  />
                  <Route
                    exact
                    path={"/admin/orders/:id"}
                    component={AdminOrderDetail}
                  />

                  <Route exact component={NotFound} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
