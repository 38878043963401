import axios from "axios";
import { logout } from "./actions/userAction";
import { store } from "..";

let baseValues = {
  baseProtocol: {
    prod: "http://",
    staging: "https://",
  },
  baseHost: {
    prod: "localhost:4000",
    staging: "hopsies-backend-pi.vercel.app",
  },
};

// export let mode = "prod";
export let mode = "staging";

let baseProtocol = baseValues.baseProtocol[mode];
let baseHost = baseValues.baseHost[mode];
export const baseURL = baseProtocol + baseHost;

const HTTP = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to modify the request headers
HTTP.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("userInfo")?.replace(/"/g, ""); 

    // If a token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);


// Add a response interceptor to catch 401 errors
HTTP.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Dispatch the logout action
      
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export { HTTP };
