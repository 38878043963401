import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

const Cancellation = ({ location }) => {
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <title>Hopsies | Cancellation/Refund Policy</title>
        <meta name="description" content="Cancellation/Refund Policy page of Hopsies Kids Wear." />
      </Helmet>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>Cancellation/Refund Policy</BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />

        {/* section title with text */}
        <div className={`welcome-area pt-100 pb-95`}>
          <div className="container">
            <h1 className="text-center">Cancellation/Refund Policy</h1>
            <div className="welcome-content text-left">
              <h2>Order Cancellation</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                You may cancel your order before the product has been shipped. Once the order is processed and shipped, cancellation is not possible. To cancel your order, please contact our customer support at{" "}
                <a href="mailto:hopsiescare@gmail.com">hopsiescare@gmail.com</a>.
              </p>

              <h2>Refund Policy</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                If you successfully cancel your order before shipment, a full refund will be initiated. The refund will be processed to the same UPI or bank account used during the purchase. Please allow 5-7 business days for the refund to reflect in your account.
              </p>

              <h2>Exceptions</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                Refunds are not available for orders that have been shipped. We encourage you to review your order carefully before finalizing your purchase.
              </p>

              <h2>Contact Us</h2>
              <p className="m-0 pt-2 pb-4 w-100">
                If you have any questions regarding our cancellation and refund policies, please reach out to us at{" "}
                <a href="mailto:hopsiescare@gmail.com">hopsiescare@gmail.com</a>.
              </p>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Cancellation.propTypes = {
  location: PropTypes.object,
};

export default Cancellation;
