import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const MobileSearch = (props) => {
  const [query, setQuery] = useState("");
  const history = useHistory();

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      history.push(`/shop-all?query=${encodeURIComponent(query)}`);
    }
    props.closemenu();
  };

  return (
    <div className="offcanvas-mobile-search-area">
      <form onSubmit={handleSearch}>
        <input
          type="search"
          placeholder="Search ..."
          style={{ paddingRight: "40px" }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
