import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    MY_ORDERS_REQUEST,
    MY_ORDERS_SUCCESS,
    MY_ORDERS_FAIL,
    ALL_ORDERS_REQUEST,
    ALL_ORDERS_SUCCESS,
    ALL_ORDERS_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    CLEAR_ERRORS,
    CANCEL_ORDER_REQUEST,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAIL,
    RETURN_ORDER_REQUEST,
    RETURN_ORDER_SUCCESS,
    RETURN_ORDER_FAIL,
    RETURN_UPDATE_REQUEST,
    RETURN_UPDATE_SUCCESS,
    RETURN_UPDATE_FAIL,
  } from "../constants/orderConstant";
  
  import axios from "axios";
import { DELETE_ALL_FROM_CART } from "./cartActions";
import { logout } from "./userAction";
import { BaseUrl } from "../constants/api";
import { HTTP } from "../api";
  
  // Create Order
  export const createOrder = (shippingInfo,orderItems,itemsPrice,taxPrice,shippingPrice,paymentInfo,totalPrice) => async (dispatch,getState) => {
    try {
      dispatch({ type: CREATE_ORDER_REQUEST });
  
      const { data } = await axios.post(`${BaseUrl}/api/v1/order/new`, {shippingInfo,orderItems,itemsPrice,taxPrice,shippingPrice,paymentInfo,totalPrice});
      
      dispatch({ type: CREATE_ORDER_SUCCESS, payload: data });
      dispatch({type:DELETE_ALL_FROM_CART})
    } catch (error) {
      console.log(error,'error')
      dispatch({
        type: CREATE_ORDER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
  
  // My Orders
  export const myOrdersGet = () => async (dispatch) => {    
    try {
      dispatch({ type: MY_ORDERS_REQUEST });
  
      const { data } = await HTTP.get(`/api/v1/orders/me`);
  
      dispatch({ type: MY_ORDERS_SUCCESS, payload: data.orders });
    } catch (error) {
      dispatch({
        type: MY_ORDERS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Get All Orders (admin)
  export const getAllOrders = () => async (dispatch,getState) => {
    dispatch({ type: ALL_ORDERS_REQUEST });
    try {
      const { data } = await HTTP.get(`/api/v1/admin/orders`);
  
      dispatch({ type: ALL_ORDERS_SUCCESS, payload: data.orders });
    } catch (error) {
      dispatch({
        type: ALL_ORDERS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Update Order
  export const updateOrder = (id, status) => async (dispatch,getState) => {
    dispatch({ type: UPDATE_ORDER_REQUEST });
    try {
      const { data } = await HTTP.put(
        `${BaseUrl}/api/v1/admin/order/${id}`,
        {status},
      );
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  


  // Update Return status
  export const updateReturnStatus = (id, status) => async (dispatch,getState) => {
    try {
      dispatch({ type: RETURN_UPDATE_REQUEST });

      const { data } = await HTTP.put(
        `${BaseUrl}/api/v1/admin/order/return/${id}`,
        {status},
    
      );
  
      dispatch({ type: RETURN_UPDATE_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: RETURN_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  // Delete Order
  export const deleteOrder = (id) => async (dispatch,getState) => {
    dispatch({ type: DELETE_ORDER_REQUEST });
    try {
  
      const { data } = await HTTP.delete(`/api/v1/admin/order/${id}`);
  
      dispatch({ type: DELETE_ORDER_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: DELETE_ORDER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Get Order Details
  export const getOrderDetails = (id) => async (dispatch,getState) => {
    dispatch({ type: ORDER_DETAILS_REQUEST });
    try {
      const { data } = await HTTP.get(`/api/v1/order/${id}`);
      dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data.order });
    } catch (error) {
   
      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

    // Cancel Details
    export const cancelOrderApi = (id) => async (dispatch,getState) => {
      try {
        dispatch({ type: CANCEL_ORDER_REQUEST });
    
        const { data } = await HTTP.put(`/api/v1/order/cancel/${id}`);
    
        dispatch({ type: CANCEL_ORDER_SUCCESS, payload: data.order });
      } catch (error) {
        dispatch({
          type: CANCEL_ORDER_FAIL,
          payload: error.response.data.message,
        });
      }
    };

    
    // Return Order
    export const returnOrderApi = (id,reason) => async (dispatch,getState) => {
      dispatch({ type: RETURN_ORDER_REQUEST });
      try {
        const { data } = await HTTP.put(`${BaseUrl}/api/v1/order/return/${id}`,{reason});
    
        dispatch({ type: RETURN_ORDER_SUCCESS, payload: data.order });
      } catch (error) {
        console.log(error,'error')
        dispatch({
          type: RETURN_ORDER_FAIL,
          payload: error.response.data.message,
        });
      }
    };
  
  // Clearing Errors
  export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  